import { createApp } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";

import { createHead } from "unhead";
import AclImg from "@/components/img.vue";

import pinia from "./store/pinia";
import routerLink from "@/components/link.vue";
import vTrack from "./directive/track";
import App from "./App.vue";
import AppH5 from "./AppH5.vue";
import "./styles/reset.scss";
import "./styles/common.scss";

import { createVueRouter } from "./router";
import { initI18n, i18n } from "./i18n";
import { isMobile } from "@/util/tool";
import { getGlobalUser, getCategoryList } from "@/util/user";

async function startApp() {
  let app;
  if (isMobile()) {
    app = createApp(AppH5);
  } else {
    app = createApp(App);
  }
  app.use(pinia);
  app.component("acl-img", AclImg);
  app.directive("track", vTrack);
  createHead();
  getGlobalUser();
  getCategoryList();
  await Promise.all([
    initI18n(),
    (() => {
      const router: any = createVueRouter();
      app.use(router);
      app.use(
        createGtm({
          id: "GTM-WV8B9F3X", // Your GTM single container ID,
          defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
          compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
          enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
          debug: true, // Whether or not display console logs debugs (optional)
          loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
          vueRouter: router, // Pass the router instance to automatically sync with router (optional)
          trackOnNextTick: false,
        }),
      );
      app.component("a-link", routerLink);
      app.use(i18n);
    })(),
  ]);

  app.mount("#app");
  return app;
}

startApp().catch(console.error); // handle error
