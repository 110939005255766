import { apiRequest, cacheRequest } from "./base";

export const getCategory = () => {
  const res = cacheRequest.get("/v1/category/all");
  return res;
};

export const getManufacturer = () => {
  const res = cacheRequest.get(`/v1/manufacturer/`);
  return res;
};

export const getManufacturerDetail = (urlTag) => {
  const res = cacheRequest.get(`/v1/manufacturer/${urlTag}`);
  return res;
};

export const getHotSale = () => {
  const res = apiRequest.get(`/v1/sku/hot-sale`);
  return res;
};

export const sendMessageBoard = (data) => {
  const res = apiRequest.post(`/v1/message-board`, data);
  return res;
};
