import { apiRequest, cacheRequest } from "./base";

export const getSkuList = (data) => {
  const res = apiRequest.post("/v1/sku", data);
  return res;
};

export const getSkuDetail = async (id) => {
  const token = localStorage.getItem("token");
  const url = `/v1/sku/${id}`;
  const res = await cacheRequest.get(url);
  return res;
};

export const getCategory = (params) => {
  const res = cacheRequest.get("/v1/category/keyword", {
    params,
  });
  return res;
};

export const getFilter = (params) => {
  const res = cacheRequest.get("/v1/category/filter", {
    params,
  });
  return res;
};
